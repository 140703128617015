<section id="newsletter">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 offset-xl-4 col-md-6 offset-md-3">
        <form
          [formGroup]="subscriptionForm"
          (submit)="subscriptionFormSubmit()"
          autocomplete="off"
        >
          <h6 class="text-center">Newsletter</h6>
          <div class="fieldDiv row no-gutters" *ngIf="!subscriptionSuccess">
            <div class="col-8">
              <input
                [ngClass]="{ 'text-success': subscriptionError }"
                autocomplete="off"
                name="box_email"
                formControlName="email"
                type="email"
                placeholder="Email Id"
                (change)="changeEmailID()"
              />
            </div>
            <div class="col-4">
              <button type="submit" class="btn btn-pinks btn-block">
                Subscribe
              </button>
            </div>
          </div>
          <control-messages
            [control]="subscriptionForm.controls.email"
          ></control-messages>
          <div *ngIf="subscriptionSuccess" class="text-center font16">
            {{ "subscription.email.success" | translate }}
          </div>
          <div *ngIf="subscriptionError" class="font10">
            {{ "subscription.email.error" | translate }}
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<footer>
  <div class="container">
    <div class="row fTop">
      <div class="col-xl-3 col-sm-4">
        <div>
          <h6>Why BOXXPORT</h6>
          <ul>
            <li>
              <a target="_blank" [routerLink]="['sell-shipping-containers']"
                >Selling on BOXXPORT</a
              >
            </li>
            <li>
              <a target="_blank" [routerLink]="['buy-shipping-containers']"
                >Buying on BOXXPORT</a
              >
            </li>
            <li>
              <a target="_blank" [routerLink]="['buyback-shipping-containers']"
                >Container BuyBack</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h6>Follow us</h6>
          <ul class="socialMedia d-flex marginT10 justify-content-between">
            <li><a href="javascript:void(0);" role="button">Whatsapp</a></li>
            <li>
              <a href="https://www.instagram.com/boxxport" target="_blank"
                >Instalagram</a
              >
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/boxxport"
                target="_blank"
                >Linkedin</a
              >
            </li>
            <!-- <li><a href="https://twitter.com/boxxport" target="_blank">Twitter</a></li> -->
            <li>
              <a href="https://www.facebook.com/boxxport.gmbh" target="_blank"
                >Facbook</a
              >
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCMmv0wqNicxz-V2yyWydkhg"
                target="_blank"
                >Youtube</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-3 offset-xl-1 col-sm-3">
        <h6>Our Company</h6>
        <ul>
          <li>
            <a target="_blank" [routerLink]="['aboutUs']">About us</a>
          </li>
          <li>
            <a target="_blank" [routerLink]="['ourTeam']">Our Team</a>
          </li>
          <li>
            <a target="_blank" [routerLink]="['/careers']">Careers</a>
          </li>
          <li>
            <a target="_blank" [routerLink]="['/blogs']">Blogs</a>
          </li>
        </ul>
      </div>
      <div class="col-xl-4 offset-xl-1 col-sm-5">
        <h6>Most searched locations</h6>
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/china']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in China
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/usa']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in USA
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/germany']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in Germany
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/poland']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in Poland
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/india']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in India
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/canada']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in Canada
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/spain']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in Spain
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr />
  <div class="container fBot">
    <div class="row">
      <div class="col-md-9 font14 text-center text-sm-left">
        <ul>
          <li>
            <a target="_blank" [routerLink]="['imprint']">Imprint</a>
          </li>
          <li>
            <a target="_blank" [routerLink]="['privacy-policy']"
              >Privacy Policy</a
            >
          </li>
          <li><a target="_blank" [routerLink]="['terms']">Terms</a></li>
          <li>
            <a target="_blank" [routerLink]="['negotiation-policy']"
              >Negotiation Policy</a
            >
          </li>
        </ul>
        <ul class="mt-4">
          <li>Grimm 14, 20457 Hamburg</li>
          <li>
            <a href="mailto:hello@boxxport.com" class="font14"
              >hello@boxxport.com
            </a>
          </li>
          <li>
            <a href="tel:+49(0)40 311 71 25 0" class="font14"
              >+49(0)40 311 71 25 0</a
            >
          </li>
        </ul>
        &copy; BOXXPORT Trading Gmbh copyright reserved
      </div>
      <div class="col-md-3 text-md-right text-center">
        <img
          src="{{ assetPath }}/images/svg/logo.svg"
          title="BOXXPORT.com Logo"
          alt="BOXXPORT.com online marketplace logo"
        />
      </div>
    </div>
  </div>
  <div class="text-center text-white small" [class.hideData]="!isDev">
    <hr />
    {{ version }}
    <div>
      <span *ngIf="effectiveType"
        >(Connection Type :: {{ effectiveType }})</span
      >
      <span *ngIf="downlinkMax">(Connection Speed :: {{ downlinkMax }})</span>
    </div>
  </div>
</footer>
